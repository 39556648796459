body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif, 'inter';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/*h0 {
  color: #000;
  !* h_0(bold) *!
  font-family: Inter, sans-serif;
  font-size: 50px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}*/

h1 {
  color: #000;

  /* h_1(bold) */
  font-family: Inter, sans-serif;
  font-size: 45px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;

  margin: 0;
  padding: 0;
  text-align: center;
}

h2 {
  color: #000;

  /* h_2(semi)bold) */
  font-family: Inter, sans-serif;
  font-size: 35px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;

  margin: 0;
  padding: 0;

}

h3 {
  color: var(--secondary);
  font-family: Inter, sans-serif;
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;

  margin: 0;
  padding: 0;
}

h4 {
  color: var(--secondary);
  text-align: center;
  /* h_4(semi_bold) */
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;

  margin: 0;
  padding: 0;
}

p {
  color: var(--secondary);
  /* h_3(bold) */
  font-family: Inter, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin: 0;
}



code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
