.header-div {
  height: 3px;
  width: calc(100% - 48px);
  background: var(--primary);
  position: fixed;
  top: 64px;
  display: none;
}

.header {
  position: fixed; /* Фиксируем хедер */
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: calc(100% - 128px); /* 100% ширины минус два отступа по 42px */
  max-width: 1196px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 17px 28px 18px;
  background-color: var(--white);
  border-radius: 0 0 50px 50px;
  border-right: 3px solid var(--primary);
  border-bottom: 3px solid var(--primary);
  border-left: 3px solid var(--primary);
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  z-index: 1000; /* Чтобы хедер всегда был сверху */
}

.nav {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  gap: 40px;
}


.header .logo { color: var(--primary); }

.nav ul {
  list-style-type: none; /* Убирает маркеры */
  display: flex;
  gap: 40px;
  margin: 0;
  padding: 0;
}

.header a {
  text-decoration: none;
  font-weight: 600;
  font-size: 16px;
  color: var(--secondary);
  cursor: pointer;
  padding: 0 24px;
}

.bot-button {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
}


/* MEDIA QUERIES */

@media (max-width:800px) {

  .nav {
    display: none;
  }

  .header {
    border-radius: 0;
    border-left: none;
    border-right: none;
    box-shadow: none;
    width: 100%;
    padding: 24px 30px;
    justify-content: space-around;
  }
}

/* for mobile */

@media (max-width: 500px) {
  .qr_code{
    display: none;
  }
  .header h2 {
    font-size: 25px;
    margin-top: 2px;
    padding: 0 24px;
  }

  .header {
    width: 100%;
    padding: 0;
    margin: 0;
    justify-content: space-between;
    border-bottom: none;
  }
  
  .header-div{
    display: block;
  }

  .bot-button {
    gap: 0px;
  }

  .bot-button img {
    padding: 15px 24px;
  }

  .bot-button span {
    display: none;
  }

}

