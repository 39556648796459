.footer {
  position: absolute;
  bottom: 0;
  display: flex;
  left: 50%;
  transform: translateX(-50%);
  width: calc(100% - 128px); /* 100% ширины минус два отступа по 42px */
  max-width: 1196px;
  justify-content: space-between;
  align-items: center;
  padding: 17px 28px 18px;
  background-color: var(--white);
  border-radius: 50px 50px 0 0;
  border-right: 3px solid var(--primary);
  border-top: 3px solid var(--primary);
  border-left: 3px solid var(--primary);
  font-family: 'Quicksand', sans-serif;
}

.branding{
  display: flex;
  flex-direction: column;
  align-items: start;
}

.branding p{
  font-size: 12px;
  margin: 0;
}

.logo {
  color: var(--primary);
}

.mailing{
  display: flex;
  flex-direction: row;
  align-items: center;
  gap : 10px;
}

.mailing img{
  width: 24px;
  height: 24px;
}

.footer a {
  text-decoration: none;
  font-weight: 600;
  font-size: 16px;
  color: var(--secondary);
  cursor: pointer;
}

 .links {
  display: flex;
  flex-direction: column;
  align-items: end;
  justify-self: center;
  /* Matching the nav gap from header */
}

.links a {
  font-size: 14px;
  margin: 0 10px;
  text-decoration: none;
  color: var(--secondary);
}

/* MEDIA QUERIES */

@media (max-width:800px) {
  .footer {
    border-radius: 0;
    border-left: none;
    border-right: none;
    flex-direction: column;
    align-items: center;
    gap: 12px;
    text-align: center;
  }
  .branding {
    align-items: center;
  }
  .links {
    align-items: center;
  }
  .mailing img{
    display: none;
  }

}