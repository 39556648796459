:root {
  --primary: #F8D307;
  --white: #fff;
  --black: #000;
  --secondary: #909090;
}

.App {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
}

.qr_code {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: fixed;
  bottom: 40px;
  right: 42px;
}
.qr_code span {
  font-size: 13px;
  font-weight: 700;
  color: var(--secondary);
  display: block;
  text-align: center;
  padding: 5px;
}
.qr_code img{
  border-radius: 8px;
  width: 137px;
}

.hero {
  margin-top: 250px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 48px;
  padding: 0 20px;
}

.hero-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 48px;
  padding: 0 20px;
}

.hero h3 {
  max-width: 812px;
  text-align: center;
}

.try-tg-btn{
  display: none;
}

.benefits {
  padding: 152px 0 0 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1028px;
  background-color: #fff;
}

.benefits h1 {
  padding: 0 24px 0;
  text-align: center;
}

.benefits-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: 86px;
  gap: 28px;
}

.benefits-info:first-child {
  align-items: end;
  text-align: end;
  max-width: 346px;
}

.benefits-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* Вирівнює контент по центру */
  text-align: center;
  max-width: 300px;
  gap: 12px;
}

.benefits-right {
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 48px;
}

.benefits-right .benefits-info {
  align-items: start;
  text-align: start;
}

.benefits-info img {
  height: 49px;
}

.benefits-phone {
  width: 270px;
}

.how-it-works {
  padding: 220px 0 0 0;
  text-align: center;
  background-color: transparent;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.how-it-works-container {
  padding: 106px 0 0 0;
  display: flex;
  align-items: center;
  gap: 25px;
  max-width: 873px;
  width: 100%;
}

.how-it-works-number {
  font-family: Inter, sans-serif;
  font-size: 50px;
  font-weight: 700;
  color: var(--primary);
}

.how-it-works-line {
  width: 5px;
  height: 84px;
  background-color: var(--primary);
}

.how-it-works-left {
  display: flex;
  flex-direction: column;
  align-items: end;
  text-align: end;
  gap: calc(84px + 20px + 13px);
}

.how-it-works-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.how-it-works-right {
  display: flex;
  flex-direction: column;
  align-items: start;
  text-align: start;
  gap: calc(84px + 20px + 15px);
}

.how-it-works-right p { max-width: 320px;}
.how-it-works-left p { max-width: 340px;}

.hiw-mobile-numbers {display: none;}
.hiw-mobile-text { display: none}


.join {
  display: flex;
  flex-direction: column;
  padding: 285px 0;
  text-align: center;
  align-items: center;
  width: 100%;
  gap: 60px;
}

.join h3 { max-width: 880px; }

.join-button {
  padding: 15px 120px;
  background-color: var(--primary);
  border: none;
  border-radius: 30px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
}

/* MEDIA */

@media (max-width: 900px) {
  .qr_code img {
    width: 120px;
  }
  .benefits-info:first-child {
    max-width: 250px;
  }
}

@media (max-width: 800px) {
  .qr_code {
    z-index: 100;
  }
}

/* for mobile */
@media (max-width:500px) {
  h1 { font-size: 30px;}
  .hero {
    margin: 0;
    padding: 0 26px 0;
    min-height: 100vh;
    justify-content: center;
  }
  .hero-content{align-self: center;}

  .hero h3 {font-size: 18px;}

  .hero img[alt="YeeDee logo"] {width: 100px;}

  @media (max-height: 760px) {
    /*.hero{
      padding: 70px 26px 0;
      height: calc(100vh - 70px);
    }*/
    .hero-content{
      padding-top: 70px;
      gap: 35px;
    }

  }

  .try-tg-btn{
    margin-top: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px 26px;
    background: var(--primary);
    text-decoration: none;
    border-radius: 20px;
    gap:8px;
  }
  .try-tg-btn img {
    width: 25px;
  }
  .try-tg-btn span{
    font-family: Inter, sans-serif;
    font-weight: 700;
    font-size: 16px;
    color: white;
  }

  .benefits {
    padding: 42px 26px 0;
    background: var(--primary);
    min-height: calc(100vh - 62px - 42px);
  }
  .benefits-phone {
    display: none;
  }

  .benefits-container {
    margin-top: 50px;
    flex-direction: column;
    gap: 45px;
  }

  .benefits-container img {
    filter: brightness(0) invert(1); /* Изменяет цвет SVG на белый */
  }

  .benefits-right {
    align-items: center;
    gap: 45px;
  }

  .benefits-info p {
    color: white;
  }

  #benefits-people {
    align-items: center;
    text-align: center;
    gap: 20px;
    order: -1;
    max-width: 307px;
  }

  #benefits-business {
    align-items: center;
    text-align: center;
    gap: 20px;
    order: 2;
    max-width: 307px;
  }

  #benefits-planet {
    align-items: center;
    text-align: center;
    gap: 20px;
    order: 3;
    max-width: 307px;
  }
  
  @media (max-height: 730px) {
    .benefits-container {
      gap: 30px;
    }

  }
  @media(max-height: 700px){
    .benefits{
      min-height: calc(100vh);
    }
  }

  .how-it-works {
    min-height: calc(100vh - 62px - 82px);
    padding: 82px 20px 0;
    align-self: start;
    width: calc(100% - 40px);
  }
  .how-it-works h1{
    align-self: center;
  }

  .how-it-works-container{
    padding: 50px 0 0 0;
    gap:0;
  }

  .how-it-works-left{ display: none}
  .how-it-works-right{ display: none}

  .hiw-mobile-numbers{
    margin-top: 3px;
    margin-right: 4px;
    display: flex;
    flex-direction: column;
    gap: 182px;
  }

  .how-it-works-center img {width: 60px;}
  .how-it-works-center img[alt="Cart"]{width: 65px;}
  .hiw-mobile-numbers span{font-size: 40px !important; font-weight: 600;}

  .how-it-works-center{
    gap: 20px;
    margin-right: 16px;
  }
  .how-it-works-line{
    height: 120px;
  }

  .hiw-mobile-text{
    text-align: start;
    margin-top: 8px;
    display: flex;
    flex-direction: column;
    gap: 162px;
  }

  /*@media (max-height: 720px) {
    .how-it-works-line{
      height: 80px;
    }

  }*/

  .join {
    padding: 100px 30px 0;
    width: calc(100% - 60px);
    min-height: calc(100vh - 62px - 100px);
  }
  .join h3 {
    font-size: 20px;
    font-weight: 600;
  }

 @media (max-height: 700px) {
   .join{
     padding-bottom: 50px;
   }
 }

  @media (max-height: 680px) {
    .join {
      padding-bottom: 80px;
    }
  }

  @media (max-height: 660px) {
    .join {
      padding-bottom: 120px;
    }
  }
}